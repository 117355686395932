export default {
  'menu.user.info': 'User Info',
  'userInfo.editUserInfo': 'Edit Info',
  'userInfo.tab.title.overview': 'Overview',
  'userInfo.tab.title.project': 'Project',
  'userInfo.tab.title.team': 'My Team',
  'userInfo.title.latestActivity': 'Latest Activity',
  'userInfo.title.latestNotification': 'In-site Notification',
  'userInfo.title.myProject': 'My Project',
  'userInfo.showMore': 'Show More',
  'userInfo.viewAll': 'View All',
  'userInfo.nodata': 'No Data',
  'userInfo.visits.unit': 'times',
  'userInfo.visits.lastMonth': 'Last Month',
};
