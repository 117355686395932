export default {
  'menu.visualization.multiDimensionDataAnalysis': 'Multi-D Analysis',
  'multiDAnalysis.card.title.dataOverview': 'Overview',
  'multiDAnalysis.dataOverview.contentProduction': 'Content Production',
  'multiDAnalysis.dataOverview.contentClick': 'Content Click',
  'multiDAnalysis.dataOverview.contentExposure': 'Content Exposure',
  'multiDAnalysis.dataOverview.activeUsers': 'Active Users',
  'multiDAnalysis.card.title.userActions': 'User Actions',
  'multiDAnalysis.card.title.contentTypeDistribution':
    'Content Type Distribution',
  'multiDAnalysis.card.title.retentionTrends': 'Retention Trends',
  'multiDAnalysis.card.title.userRetention': 'User Retention',
  'multiDAnalysis.card.title.contentConsumptionTrends':
    'Content Consumption Trends',
  'multiDAnalysis.card.title.contentConsumption': 'Content Consumption',
  'multiDAnalysis.card.title.contentPublishingSource':
    'Content Publishing Source',
};
