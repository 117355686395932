export default {
  'menu.visualization.dataAnalysis': '分析页',
  'dataAnalysis.title.publicOpinion': '舆情分析',
  'dataAnalysis.card.title.allVisitors': '访问总人次',
  'dataAnalysis.card.title.contentPublished': '内容发布量',
  'dataAnalysis.card.title.totalComment': '评论总量',
  'dataAnalysis.card.title.totalShare': '分享总量',
  'dataAnalysis.card.yesterday': '较昨日',
  'dataAnalysis.contentPublishRatio': '内容发布比例',
  'dataAnalysis.popularAuthor': '热门作者榜单',
  'dataAnalysis.popularAuthor.column.ranking': '排名',
  'dataAnalysis.popularAuthor.column.author': '作者',
  'dataAnalysis.popularAuthor.column.content': '内容量',
  'dataAnalysis.popularAuthor.column.click': '点击量',
  'dataAnalysis.contentPeriodAnalysis': '内容时段分析',
};
