export default {
  'menu.profile.basic': 'Basic Profile',
  'basicProfile.title.form': 'Parameter Approval Process Table',
  'basicProfile.steps.commit': 'Commit',
  'basicProfile.steps.approval': 'Approval',
  'basicProfile.steps.finish': 'Finish',
  'basicProfile.title.currentParams': 'Current Parameters',
  'basicProfile.title.originParams': 'Original Parameters',
  'basicProfile.title.video': 'Video Parameters',
  'basicProfile.title.audio': 'Audio Parameters',
  'basicProfile.title.preVideo': 'Original video parameters',
  'basicProfile.title.preAudio': 'Original audio parameters',
  'basicProfile.label.video.mode': 'Match Mode',
  'basicProfile.label.video.acquisition.resolution': 'Acquisition Resolution',
  'basicProfile.label.video.acquisition.frameRate': 'Acquisition Frame Rate',
  'basicProfile.label.video.encoding.resolution': 'Encoding Resolution',
  'basicProfile.label.video.encoding.rate.min': 'Encoding Min Rate',
  'basicProfile.label.video.encoding.rate.max': 'Encoding Max Rate',
  'basicProfile.label.video.encoding.rate.default': 'Encoding Default Rate',
  'basicProfile.label.video.encoding.frameRate': 'Encoding Frame Rate',
  'basicProfile.label.video.encoding.profile': 'Encoding Profile',
  'basicProfile.label.audio.mode': 'Match Mode',
  'basicProfile.label.audio.acquisition.channels': 'Acquisition Channels',
  'basicProfile.label.audio.encoding.channels': 'Encoding Channels',
  'basicProfile.label.audio.encoding.rate': 'Encoding Rate',
  'basicProfile.label.audio.encoding.profile': 'Encoding Profile',
  'basicProfile.unit.audio.channels': 'channels',
  'basicProfile.goBack': 'GoBack',
  'basicProfile.cancel': 'Cancel Process',
  'basicProfile.title.operationLog': 'Operation Log',
  'basicProfile.column.contentNumber': 'Content Number',
  'basicProfile.column.updateContent': 'Update Content',
  'basicProfile.column.status': 'Status',
  'basicProfile.column.updateTime': 'Update Time',
  'basicProfile.column.operation': 'Operation',
  'basicProfile.cell.pass': 'Pass',
  'basicProfile.cell.auditing': 'Auditing',
  'basicProfile.cell.view': 'View',
};
