export default {
  'menu.form.group': 'Group Form',
  'groupForm.title.video': 'Video Parameters',
  'groupForm.title.audio': 'Audio Parameters',
  'groupForm.title.description': 'Enter Description',
  'groupForm.form.label.video.mode': 'Match Mode',
  'groupForm.form.label.video.acquisition.resolution': 'Acquisition Resolution',
  'groupForm.form.label.video.acquisition.frameRate': 'Acquisition Frame Rate',
  'groupForm.form.label.video.encoding.resolution': 'Encoding Resolution',
  'groupForm.form.label.video.encoding.rate.min': 'Encoding Min Rate',
  'groupForm.form.label.video.encoding.rate.max': 'Encoding Max Rate',
  'groupForm.form.label.video.encoding.rate.default': 'Encoding Default Rate',
  'groupForm.form.label.video.encoding.frameRate': 'Encoding Frame Rate',
  'groupForm.form.label.video.encoding.profile': 'Encoding Profile',
  'groupForm.placeholder.video.mode': 'Please Select',
  'groupForm.placeholder.video.acquisition.resolution': 'Please Select',
  'groupForm.placeholder.video.acquisition.frameRate': 'Enter Range [1, 30]',
  'groupForm.placeholder.video.encoding.resolution': 'Please Select',
  'groupForm.placeholder.video.encoding.rate.min': 'Enter Range [150, 1800]',
  'groupForm.placeholder.video.encoding.rate.max': 'Enter Range [150, 1800]',
  'groupForm.placeholder.video.encoding.rate.default':
    'Enter Range [150, 1800]',
  'groupForm.placeholder.video.encoding.frameRate': 'Enter Range [1, 30]',
  'groupForm.placeholder.video.encoding.profile': 'Enter Range [150, 1800]',
  'groupForm.form.label.audio.mode': 'Match Mode',
  'groupForm.form.label.audio.acquisition.channels': 'Acquisition Channels',
  'groupForm.form.label.audio.encoding.rate': 'Encoding Rate',
  'groupForm.form.label.audio.encoding.channels': 'Encoding Channels',
  'groupForm.placeholder.audio.encoding.channels': 'Enter Range [150, 1800]',
  'groupForm.form.label.audio.encoding.profile': 'Encoding Profile',
  'groupForm.placeholder.audio.mode': 'Please Select',
  'groupForm.placeholder.audio.acquisition.channels': 'Please Select',
  'groupForm.placeholder.audio.encoding.rate': 'Enter Range [150, 1800]',
  'groupForm.placeholder.audio.encoding.profile': 'Enter Range [1, 30]',
  'groupForm.form.label.parameterDescription': 'Parameter Description',
  // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#list-interpolation
  'groupForm.placeholder.description': `Please fill in the parameter description with a maximum of 200 words`,
  'groupForm.submit': 'Submit',
  'groupForm.reset': 'Reset',
  'groupForm.submitSuccess': 'Submit Success',
};
