export default {
  'menu.list.cardList': '卡片列表',
  'cardList.tab.title.all': '全部',
  'cardList.tab.title.content': '内容质检',
  'cardList.tab.title.service': '开通服务',
  'cardList.tab.title.preset': '规则预置',
  'cardList.searchInput.placeholder': '搜索',
  // 'cardList.statistic.enable': '已启用',
  // 'cardList.statistic.disable': '未启用',
  'cardList.content.delete': '删除',
  'cardList.content.inspection': '质检',
  'cardList.content.action': '点击创建质检内容队列',
  'cardList.service.open': '开通服务',
  'cardList.service.cancel': '取消服务',
  'cardList.service.renew': '续约服务',
  'cardList.service.tag': '已开通',
  'cardList.service.expiresTag': '已过期',
  'cardList.preset.tag': '已启用',
};
