import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_a_input_number = _resolveComponent("a-input-number");
    const _component_a_switch = _resolveComponent("a-switch");
    return $props.type === 'number' ? (_openBlock(), _createBlock(_component_a_input_number, {
        key: 0,
        style: {
            width: '80px'
        },
        size: "small",
        "default-value": $props.defaultValue,
        onChange: $setup.handleChange
    }, null, 8, /* PROPS */ [
        "default-value"
    ])) : (_openBlock(), _createBlock(_component_a_switch, {
        key: 1,
        "default-checked": $props.defaultValue,
        size: "small",
        onChange: $setup.handleChange
    }, null, 8, /* PROPS */ [
        "default-checked"
    ]));
}
