import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_icon_apps = _resolveComponent("icon-apps");
    const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item");
    const _component_a_breadcrumb = _resolveComponent("a-breadcrumb");
    return _openBlock(), _createBlock(_component_a_breadcrumb, {
        class: "container-breadcrumb"
    }, {
        default: _withCtx(()=>[
                _createVNode(_component_a_breadcrumb_item, null, {
                    default: _withCtx(()=>[
                            _createVNode(_component_icon_apps)
                        ]),
                    _: 1
                }),
                /* STABLE */ (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item)=>{
                    return _openBlock(), _createBlock(_component_a_breadcrumb_item, {
                        key: item
                    }, {
                        default: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                            ]),
                        /* TEXT */ _: 2
                    }, /* DYNAMIC */ 1024);
                }), /* DYNAMIC_SLOTS */ 128))
            ]),
        /* KEYED_FRAGMENT */ _: 1
    });
} /* STABLE */ 
