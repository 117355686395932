export default {
  'menu.form.step': '分步表单',
  'stepForm.step.title': '创建渠道表单',
  'stepForm.step.title.baseInfo': '选择基本信息',
  'stepForm.step.subTitle.baseInfo': '创建渠道活动',
  'stepForm.step.title.channel': '输入渠道信息',
  'stepForm.step.subTitle.channel': '输入详细的渠道信息',
  'stepForm.step.title.finish': '完成创建',
  'stepForm.step.subTitle.finish': '创建成功',
  'stepForm.success.title': '提交成功',
  'stepForm.success.subTitle': '表单提交成功！',
  'stepForm.button.next': '下一步',
  'stepForm.button.prev': '上一步',
  'stepForm.button.submit': '提交',
  'stepForm.button.again': '再次创建',
  'stepForm.button.view': '查看详情',
  'stepForm.form.label.activityName': '活动名称',
  'stepForm.placeholder.activityName': '输入汉字、字母或数字，最多20字符',
  'stepForm.form.error.activityName.required': '请输入活动名称',
  'stepForm.form.error.activityName.pattern':
    '输入汉字、字母或数字，最多20字符',
  'stepForm.form.label.channelType': '渠道类型',
  'stepForm.placeholder.channelType': '请选择渠道类型',
  'stepForm.form.error.channelType.required': '请选择渠道类型',
  'stepForm.form.label.promotionTime': '推广时间',
  'stepForm.form.error.promotionTime.required': '请选择推广时间',
  'stepForm.form.label.promoteLink': '推广地址',
  'stepForm.form.error.promoteLink.required': '请输入推广地址',
  'stepForm.form.error.promoteLink.pattern':
    '如 Android 或 iOS 的下载地址、中间跳转URL，网址必须以 http:// 或 https:// 开头',
  'stepForm.form.tip.promoteLink':
    '如 Android 或 iOS 的下载地址、中间跳转URL，网址必须以 http:// 或 https:// 开头',
  'stepForm.placeholder.promoteLink': '请输入推广页面地址',
  'stepForm.form.label.advertisingSource': '广告来源',
  'stepForm.placeholder.advertisingSource': '引荐来源地址：sohu、sina',
  'stepForm.form.error.advertisingSource.required': '请输入广告来源',
  'stepForm.form.label.advertisingMedia': '广告媒介',
  'stepForm.placeholder.advertisingMedia': '营销媒介：cpc、banner、edm',
  'stepForm.form.error.advertisingMedia.required': '请输入广告媒介',
  'stepForm.form.label.keyword': '关键词',
  'stepForm.placeholder.keyword': '请选择关键词',
  'stepForm.form.error.keyword.required': '请选择关键词',
  'stepForm.form.label.pushNotify': '推送提醒',
  'stepForm.form.label.advertisingContent': '广告内容',
  'stepForm.placeholder.advertisingContent':
    '请输入广告内容介绍，最多不超过200字。',
  'stepForm.form.error.advertisingContent.required': '请输入广告内容',
  'stepForm.form.error.advertisingContent.maxLength': '最多不超过200字',
  'stepForm.form.description.title': '渠道表单说明',
  'stepForm.form.description.text':
    '广告商渠道推广支持追踪在第三方广告商投放广告下载App用户的场景，例如在今日头条渠道投放下载App广告，追踪通过在渠道下载激活App的用户。',
};
