export default {
  'menu.list.cardList': 'Card List',
  'cardList.tab.title.all': 'All',
  'cardList.tab.title.content': 'Quality Inspection',
  'cardList.tab.title.service': 'The service',
  'cardList.tab.title.preset': 'Rules Preset',
  'cardList.searchInput.placeholder': 'Search',
  'cardList.enable': 'Enable',
  'cardList.disable': 'Disable',
  'cardList.content.delete': 'Delete',
  'cardList.content.inspection': 'Inspection',
  'cardList.content.action': 'Click Create Qc Content queue',
  'cardList.service.open': 'Open',
  'cardList.service.cancel': 'Cancel',
  'cardList.service.renew': 'Contract of service',
  'cardList.service.tag': 'Opened',
  'cardList.service.expiresTag': 'Expired',
  'cardList.preset.tag': 'Enable',
};
