import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n)=>(_pushScopeId("data-v-d3490518"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "block"
};
const _hoisted_2 = {
    class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString($props.title), 1),
        /* TEXT */ (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option)=>{
            return _openBlock(), _createElementBlock("div", {
                key: option.name,
                class: "switch-wrapper"
            }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t(option.name)), 1),
                /* TEXT */ _createVNode($setup["FormWrapper"], {
                    type: option.type || 'switch',
                    name: option.key,
                    "default-value": option.defaultVal,
                    onInputChange: $setup.handleChange
                }, null, 8, /* PROPS */ [
                    "type",
                    "name",
                    "default-value"
                ])
            ]);
        }), 128))
    ]);
} /* KEYED_FRAGMENT */ 
