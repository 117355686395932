export default {
  'menu.visualization.multiDimensionDataAnalysis': '多维数据分析',
  'multiDAnalysis.card.title.dataOverview': '数据总览',
  'multiDAnalysis.dataOverview.contentProduction': '内容生产量',
  'multiDAnalysis.dataOverview.contentClick': '内容点击量',
  'multiDAnalysis.dataOverview.contentExposure': '内容曝光量',
  'multiDAnalysis.dataOverview.activeUsers': '活跃用户数',
  'multiDAnalysis.card.title.userActions': '今日转评赞统计',
  'multiDAnalysis.card.title.contentTypeDistribution': '内容题材分布',
  'multiDAnalysis.card.title.retentionTrends': '用户留存趋势',
  'multiDAnalysis.card.title.userRetention': '用户留存量',
  'multiDAnalysis.card.title.contentConsumptionTrends': '内容消费趋势',
  'multiDAnalysis.card.title.contentConsumption': '内容消费量',
  'multiDAnalysis.card.title.contentPublishingSource': '内容发布来源',
};
