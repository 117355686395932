export default {
  'menu.user.setting': '用户设置',
  'userSetting.menu.title.info': '个人信息',
  'userSetting.menu.title.account': '账号设置',
  'userSetting.menu.title.password': '密码',
  'userSetting.menu.title.message': '消息通知',
  'userSetting.menu.title.result': '结果页',
  'userSetting.menu.title.data': '导出数据',
  'userSetting.saveSuccess': '保存成功',
  'userSetting.title.basicInfo': '基本信息',
  'userSetting.title.socialInfo': '社交信息',
  'userSetting.label.avatar': '头像',
  'userSetting.label.name': '用户名',
  'userSetting.label.location': '办公地点',
  'userSetting.label.introduction': '个人简介',
  'userSetting.label.personalWebsite': '个人网站',
  'userSetting.save': '保存',
  'userSetting.cancel': '取消',
  'userSetting.reset': '重置',
  // new
  'userSetting.label.certification': '实名认证',
  'userSetting.label.phone': '手机号码',
  'userSetting.label.accountId': '账号ID',
  'userSetting.label.registrationDate': '注册时间',
  'userSetting.tab.basicInformation': '基础信息',
  'userSetting.tab.securitySettings': '安全设置',
  'userSetting.tab.certification': '实名认证',
  'userSetting.basicInfo.form.label.email': '邮箱',
  'userSetting.basicInfo.placeholder.email': `请输入邮箱地址，如xxx{'@'}bytedance.com`,
  'userSetting.form.error.email.required': '请输入邮箱',
  'userSetting.basicInfo.form.label.nickname': '昵称',
  'userSetting.basicInfo.placeholder.nickname': '请输入您的昵称',
  'userSetting.form.error.nickname.required': '请输入昵称',
  'userSetting.basicInfo.form.label.countryRegion': '国家/地区',
  'userSetting.basicInfo.placeholder.countryRegion': '请选择',
  'userSetting.form.error.countryRegion.required': '请选择国家/地区',
  'userSetting.basicInfo.form.label.area': '所在区域',
  'userSetting.basicInfo.placeholder.area': '请选择',
  'userSetting.form.error.area.required': '请选择所在区域',
  'userSetting.basicInfo.form.label.address': '具体地址',
  'userSetting.basicInfo.placeholder.address': '请输入您的地址',
  'userSetting.basicInfo.form.label.profile': '个人简介',
  'userSetting.basicInfo.placeholder.profile':
    '请输入您的个人简介，最多不超过200字。',
  'userSetting.form.error.profile.maxLength': '最多不超过200字',
  'userSetting.SecuritySettings.form.label.password': '登录密码',
  'userSetting.SecuritySettings.placeholder.password':
    '已设置。密码至少6位字符，支持数字、字母和除空格外的特殊字符，且必须同时包含数字和大小写字母。',
  'userSetting.SecuritySettings.form.label.securityQuestion': '密保问题',
  'userSetting.SecuritySettings.placeholder.securityQuestion':
    '您暂未设置密保问题，密保问题可以有效的保护账号的安全。',
  'userSetting.SecuritySettings.form.label.phone': '安全手机',
  // 'userSetting.SecuritySettings.placeholder.phone': '已绑定：150******50',
  'userSetting.SecuritySettings.form.label.email': '安全邮箱',
  'userSetting.SecuritySettings.placeholder.email':
    '您暂未设置邮箱，绑定邮箱可以用来找回密码、接收通知等。',
  'userSetting.SecuritySettings.button.settings': '设置',
  'userSetting.SecuritySettings.button.update': '修改',
  'userSetting.certification.title.enterprise': '企业实名认证',
  'userSetting.certification.extra.enterprise': '修改认证主体',
  'userSetting.certification.label.accountType': '账号类型',
  'userSetting.certification.label.status': '认证状态',
  'userSetting.certification.label.time': '认证时间',
  'userSetting.certification.label.legalPerson': '法人姓名',
  'userSetting.certification.label.certificateType': '法人证件类型',
  'userSetting.certification.label.authenticationNumber': '法人认证号码',
  'userSetting.certification.label.enterpriseName': '企业名称',
  'userSetting.certification.label.enterpriseCertificateType': '企业证件类型',
  'userSetting.certification.label.organizationCode': '组织机构代码',
  'userSetting.certification.title.record': '认证记录',
  'userSetting.certification.columns.certificationType': '认证类型',
  'userSetting.certification.cell.certificationType': '企业证件认证',
  'userSetting.certification.columns.certificationContent': '认证内容',
  'userSetting.certification.columns.status': '当前状态',
  'userSetting.certification.cell.pass': '已通过',
  'userSetting.certification.cell.auditing': '审核中',
  'userSetting.certification.columns.time': '创建时间',
  'userSetting.certification.columns.operation': '操作',
  'userSetting.certification.button.check': '查看',
  'userSetting.certification.button.withdraw': '撤回',
};
