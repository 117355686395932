import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return $setup.renderChart ? (_openBlock(), _createBlock($setup["VCharts"], {
        key: 0,
        option: $props.options,
        autoresize: $props.autoResize,
        style: _normalizeStyle({
            width: $props.width,
            height: $props.height
        })
    }, null, 8, /* PROPS */ [
        "option",
        "autoresize",
        "style"
    ])) : _createCommentVNode("v-if", true);
}
