import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_icon_settings = _resolveComponent("icon-settings");
    const _component_a_button = _resolveComponent("a-button");
    const _component_a_alert = _resolveComponent("a-alert");
    const _component_a_drawer = _resolveComponent("a-drawer");
    return _openBlock(), _createElementBlock(_Fragment, null, [
        !$setup.appStore.navbar ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "fixed-settings",
            onClick: $setup.setVisible
        }, [
            _createVNode(_component_a_button, {
                type: "primary"
            }, {
                icon: _withCtx(()=>[
                        _createVNode(_component_icon_settings)
                    ]),
                _: 1
            })
        ])) : /* STABLE */ _createCommentVNode("v-if", true),
        _createVNode(_component_a_drawer, {
            width: 300,
            "unmount-on-close": "",
            visible: $setup.visible,
            "cancel-text": _ctx.$t('settings.close'),
            "ok-text": _ctx.$t('settings.copySettings'),
            onOk: $setup.copySettings,
            onCancel: $setup.cancel
        }, {
            title: _withCtx(()=>[
                    _createTextVNode(_toDisplayString(_ctx.$t('settings.title')), 1)
                ]),
            /* TEXT */ default: _withCtx(()=>[
                    _createVNode($setup["Block"], {
                        options: $setup.contentOpts,
                        title: _ctx.$t('settings.content')
                    }, null, 8, /* PROPS */ [
                        "options",
                        "title"
                    ]),
                    _createVNode($setup["Block"], {
                        options: $setup.othersOpts,
                        title: _ctx.$t('settings.otherSettings')
                    }, null, 8, /* PROPS */ [
                        "options",
                        "title"
                    ]),
                    _createVNode(_component_a_alert, null, {
                        default: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_ctx.$t('settings.alertContent')), 1)
                            ]),
                        /* TEXT */ _: 1
                    })
                ]),
            /* STABLE */ _: 1
        }, /* STABLE */ 8, /* PROPS */ [
            "visible",
            "cancel-text",
            "ok-text"
        ])
    ], 64);
} /* STABLE_FRAGMENT */ 
