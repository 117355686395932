export default {
  'menu.form.group': '分组表单',
  'groupForm.title.video': '视频参数',
  'groupForm.title.audio': '音频参数',
  'groupForm.title.description': '填写说明',
  'groupForm.form.label.video.mode': '匹配模式',
  'groupForm.form.label.video.acquisition.resolution': '采集分辨率',
  'groupForm.form.label.video.acquisition.frameRate': '采集帧率',
  'groupForm.form.label.video.encoding.resolution': '编码分辨率',
  'groupForm.form.label.video.encoding.rate.min': '编码码率最小值',
  'groupForm.form.label.video.encoding.rate.max': '编码码率最大值',
  'groupForm.form.label.video.encoding.rate.default': '编码码率默认值',
  'groupForm.form.label.video.encoding.frameRate': '编码帧率',
  'groupForm.form.label.video.encoding.profile': '编码profile',
  'groupForm.placeholder.video.mode': '请选择',
  'groupForm.placeholder.video.acquisition.resolution': '请选择',
  'groupForm.placeholder.video.acquisition.frameRate': '输入范围[1, 30]',
  'groupForm.placeholder.video.encoding.resolution': '请选择',
  'groupForm.placeholder.video.encoding.rate.min': '输入范围[150, 1800]',
  'groupForm.placeholder.video.encoding.rate.max': '输入范围[150, 1800]',
  'groupForm.placeholder.video.encoding.rate.default': '输入范围[150, 1800]',
  'groupForm.placeholder.video.encoding.frameRate': '输入范围[1, 30]',
  'groupForm.placeholder.video.encoding.profile': '输入范围[150, 1800]',
  'groupForm.form.label.audio.mode': '匹配模式',
  'groupForm.form.label.audio.acquisition.channels': '采集声道数',
  'groupForm.form.label.audio.encoding.rate': '编码码率',
  'groupForm.form.label.audio.encoding.channels': '编码声道数',
  'groupForm.placeholder.audio.encoding.channels': '输入范围[150, 1800]',
  'groupForm.form.label.audio.encoding.profile': '编码profile',
  'groupForm.placeholder.audio.mode': '请选择',
  'groupForm.placeholder.audio.acquisition.channels': '请选择',
  'groupForm.placeholder.audio.encoding.rate': '输入范围[150, 1800]',
  'groupForm.placeholder.audio.encoding.profile': '输入范围[1, 30]',
  'groupForm.form.label.parameterDescription': '参数说明',
  // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#list-interpolation
  'groupForm.placeholder.description': `请填写参数说明，最多不超多200字。`,
  'groupForm.submit': '提交',
  'groupForm.reset': '重置',
  'groupForm.submitSuccess': '提交成功',
};
